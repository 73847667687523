.nerNames > table {
    margin: auto;
}

.nNamesAvailable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
}

.nerSelectors {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.languageKeys {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.languageKeys > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.languageKeys > div > table{
    margin: auto;
}