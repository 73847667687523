
:root {
  --row-if-wide: row;
  --row-if-medium: row;
}

@media (max-width: 1030px) {
  :root {
    --row-if-medium: column
  }
}

@media (max-width: 750px) {
  :root {
    --row-if-wide: column
  }
}


:root {
  --default-background: #1d1d1d;
  --default-input-background: #303030;
  --default-textcolor: #bcbcbc;
  --default-blackwhite-text: #eeeeee;
}

@media (prefers-color-scheme: light) {
  :root {
    --default-background: #ffffff;
    --default-textcolor: #1d1d1d;
    --default-blackwhite-text: #000000;
    --default-input-background: white;
  }
}

:root {
  background: var(--default-background);
  background-color: var(--default-background);
  color: var(--default-textcolor);
}


.plusDice {
  padding-right: 50px;
}

.minusDice {
  padding-left: 50px;
}


.plusDie {
  color: #df0000;
}

.minusDie {
  color: var(--default-blackwhite-text);
}

.fortuneDie {
  color: #d3b700;
}
@media (prefers-color-scheme: light) {
  .fortuneDie {
    color: #a99200;
  }
}

.initiativeDie {
  color: #df0000;
}

.boxcarDie {
  color: #a84bff;
}
@media (prefers-color-scheme: light) {
  .boxcarDie {
    color: blueviolet;
  }
}

.sixDie {
  /*TODO*/
}


@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

@keyframes spinReverse {
  from {transform:rotate(360deg);}
  to {transform:rotate(0deg);}
}

@keyframes cyclePips {
  0% {
    content: "0";
  }
  33% {
    content: "1";
  }
  66% {
    content: "2";
  }
  100% {
    content: "5";
  }
}

.die {
  font-size: xxx-large;
}

.die.spinningDie0 {
  animation: spin 600ms linear infinite;
  display: inline-block;
}
.die.spinningDie1 {
  animation: spin 800ms linear infinite;
  display: inline-block;
}
.die.spinningDie2 {
  animation: spin 1s linear infinite;
  display: inline-block;
}
.die.spinningDie3 {
  animation: spin 1300ms linear infinite;
  display: inline-block;
}
.die.spinningDie4 {
  animation: spin 1700ms linear infinite;
  display: inline-block;
}
.die.spinningDie5 {
  animation: spinReverse 600ms linear infinite;
  display: inline-block;
}
.die.spinningDie6 {
  animation: spinReverse 800ms linear infinite;
  display: inline-block;
}
.die.spinningDie7 {
  animation: spinReverse 1s linear infinite;
  display: inline-block;
}
.die.spinningDie8 {
  animation: spinReverse 1300ms linear infinite;
  display: inline-block;
}
.die.spinningDie9 {
  animation: spinReverse 1700ms linear infinite;
  display: inline-block;
}

.die.spinningDie {
  animation: spin 1s linear infinite;
  display: inline-block;
}
.die.spinningDie {
  animation: spin 1s linear infinite;
  display: inline-block;
}
.die.spinningDie {
  animation: spin 1s linear infinite;
  display: inline-block;
}



/* Tooltip text */
.tooltip .tooltipText {
  visibility: hidden;
  width: 120px;
  background-color: #2c2c2c;
  color: var(--default-textcolor);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}
@media (prefers-color-scheme: light) {
  .tooltip .tooltipText {
    background-color: #e8f3f3;
  }

}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltipText {
  visibility: visible;
}


.header {
  display: flex;
  flex-direction: var(--row-if-wide);
  justify-content: space-evenly;
  align-items: center;
}

.headerToggles {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.usersPresentTitle {
  font-weight: bold;
}

.usersPresent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.charactersSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border: 1px solid grey;
  border-radius: 25px;
}

.attackSection {
  display: flex;
  flex-direction: var(--row-if-wide);
  justify-content: space-evenly;
}

.attackSectionLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border: 1px solid grey;
  border-radius: 25px;
}

.attackSectionRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border: 1px solid grey;
  border-radius: 25px;
}

.singleAttackRoll {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.attackDice {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.skillRollerHeader {
  font-weight: bold;
  font-size: large;
  text-align: center;
}

.skillRoller {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skillDiceRoll {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skillDice {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.skillDiceRollResults {
  display: flex;
  flex-direction: column;
  align-items: center;
}


table {
  border-spacing: 0;
  --table-divider-color: #c30000;
}

th {
  border-bottom: solid 3px #a90000;
  padding: 1px 4px;
}
td {
  padding:1px 4px;
}
.leftBorderTH {
  border-left: solid 1px var(--table-divider-color);
}
.leftBorderTD {
  border-left: solid 1px var(--table-divider-color);
}
.firstKFEvent {
  border-top: dashed 1px var(--table-divider-color);
}

@media (prefers-color-scheme: light) {
  th {
    border-bottom: solid 2px black;
  }

  .leftBorderTH {
    border-left: solid 1px lightgrey;
  }

  .leftBorderTD {
    border-left: solid 1px grey;
  }
}

.selectedCharacter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectedCharacterColumns {
  display: flex;
  flex-direction: var(--row-if-medium);
  align-items: flex-start;
}

.selectedCharacterLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.invalidTextCharacterField {
  background: lightcoral;
}
.invalidCharacterField {
  background: lightcoral;
}


.selectedCharacterRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.fortuneTypeSelector {
  display: inline-block;
}

.attackTypeSelector {
  display: inline-block;
}

.attackBonuses {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.oneImpairmentWP {
  background-color: #3e380d;
}
.oneImpairmentWP.selectedCharacterRow {
  background-color: #655b19;
}
.twoImpairmentWP {
  background-color: #50210b;
}
.twoImpairmentWP.selectedCharacterRow {
  background-color: #672e13;
}
.ffDownWP {
  background-color: #792b40;
}
.ffDownWP.selectedCharacterRow {
  background-color: #88334a;
}
.bossDownWP {
  background-color: #46236e;
}
.bossDownWP.selectedCharacterRow {
  background-color: #7449a5;
}
.selectedCharacterRow {
  background-color: #393939;
  font-weight: bolder;
  color: var(--default-blackwhite-text);
}
.selectedCharacterDead {
  opacity: 0.3;
}
.invisibleCharacterRow {
  opacity: 0.5;
}
.selectedCharacterDead.invisibleCharacterRow{
  opacity: 0.1;
}

@media (prefers-color-scheme: light) {
  .oneImpairmentWP {
    background-color: #fff4c8;
  }

  .oneImpairmentWP.selectedCharacterRow {
    background-color: #efdf94;
  }

  .twoImpairmentWP {
    background-color: #ffd2bb;
  }

  .twoImpairmentWP.selectedCharacterRow {
    background-color: #ecb49b;
  }

  .ffDownWP {
    background-color: #fec6dc;
  }

  .ffDownWP.selectedCharacterRow {
    background-color: #e7a0b8;
  }

  .bossDownWP {
    background-color: #f1daff;
  }

  .bossDownWP.selectedCharacterRow {
    background-color: #d4b8ef;
  }

  .selectedCharacterRow {
    background-color: #eaeaea;
    font-weight: bolder;
    color: var(--default-blackwhite-text);
  }
}


.charactersAndShotTracker {
  display: flex;
  flex-direction: var(--row-if-wide);
  justify-content: space-evenly;
  padding: 0 0 35px;
}

.attackHeader {
  font-weight: bold;
  font-size: large;
  text-align: center;
  margin-top: 10px;
}

.shotTracker {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px;
  border: 1px solid grey;
  border-radius: 25px;
}

.sequenceNumber {
  font-weight: bold;
  font-size: x-large;
}

.sequenceNumberContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width:50%;
}

.shotTrackerLower {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%
}

.shotTrackerLowerLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.shotTrackerLowerRight {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.textKeyFrameEventField {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
}

.shotTrackerTable {
  background-color: #d20000;
  color: #f3f3f3;
  font-family: Caudex, serif;
}
.initiativeCharacterShot {
  font-size: x-large;
  font-weight: bold;
  color: black;
}

.initiativeEnemyShot {
  color: #e1e1e1;
}

.initiativeKFEShot {
  font-size: large;
  font-style: italic;
  color: #e1e1e1;
}

.characterListHeading {
  font-size: large;
  font-weight: bold;
}

.initiativeShotHeader {
  font-size: xx-large;
  font-weight: bold;
}

.overdueKeyFrameEvent {
  text-decoration: line-through;
}

.selectedCharacterHeading {
  font-size: large;
  font-weight: bold;
}

.todosHeading {
  font-size: large;
  font-weight: bold;
}

.todos {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.tdSelectMany {
  text-align: center;
}

input {
  border: 1px solid grey;
  background-color: var(--default-input-background);
  background: var(--default-input-background);
  color: var(--default-textcolor);
}

select {
  border: 1px solid grey;
  background-color: var(--default-input-background);
  background: var(--default-input-background);
  color: var(--default-textcolor);
}

button {
  border: 1px solid grey;
  background-color: var(--default-input-background);
  background: var(--default-input-background);
  color: var(--default-textcolor);
}